function sendGaEvent(label) {
    gtag('event', label);
}

let elems = document.querySelectorAll('[data-toggle]');
Array.prototype.forEach.call(elems, function (el, i) {
    el.addEventListener('click', function () {
        let toggle = el.getAttribute('data-toggle');
        document.getElementById(toggle).classList.toggle('hidden');
    }, false);
});

function filterList(inputSelector, menuSelector) {
    // Declare variables
    var input, filter, menu, a, i, txtValue;
    input = document.querySelector(inputSelector);
    filter = input.value.toUpperCase();
    menu = document.querySelector(menuSelector);
    a = menu.getElementsByTagName('a');

    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < a.length; i++) {
        txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            a[i].style.display = "";
        } else {
            a[i].style.display = "none";
        }
    }
}


function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

setTimezoneCookie();
function getTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function setTimezoneCookie() {
    setCookie('detected_timezone', getTimezone(), 365);
}